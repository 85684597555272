<template>
  <div class="proffesional">
    <section class="section">
      <div class="video-container" data-simulate-touch="false" data-loop="false"
        data-autoplay="5500">
        <div class="video-wrapper">
          <div class="video-slide slide_video context-dark video-bg-overlay">
            <div style="
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    overflow: hidden;
                    background-size: cover;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: none;
                  ">
                <video loop muted class="video" autoplay="autoplay" playsinline src="video/video-lg-22.mp4">
                  <source src="video/video-lg-22" type="video/mp4" />
                  <img src="video/video-md.jpg" title="Your browser does not support video" />
                </video>
              </div>
            
            <div class="vide_bg novi-vide" data-vide-bg="video/video-lg" data-vide-options="posterType: jpg">
              <div class="slide-caption text-center">
                <div class="container">
                  <h1 data-caption-animate="fadeInUpSmall" class="title-weight">{{
                    $t('lang.professional.section_1.banner.title') }}</h1>
                  <h5 class="text-width-2 block-centered sub-font-size" data-caption-animate="fadeInUpSmall" data-caption-delay="200"
                    v-html="$t('lang.professional.section_1.banner.description')"></h5>

                  <div class="group-lg group-middle">
                    <a class="button button-primary" data-caption-animate="fadeInUpSmall" data-caption-delay="350"
                      href="/buscando"
                      @click="formClicked">
                      {{ $t('lang.professional.section_1.banner.btn') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 

    <section class="section novi-bg novi-bg-img section-md bg-default text-center" id="section-see-features">
      <div class="container width-xl">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.professional.section_2.title') }}</b>
            </h2>
            <p v-html="$t('lang.professional.section_2.description_1')"></p>
            <p v-html="$t('lang.professional.section_2.description_2')"></p>
            <p v-html="$t('lang.professional.section_2.description_3')"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="grid-section">
        <div class="cultura">
          <div class="grid-cultura">
            <div class="cultura-title">{{ $t('lang.professional.section_3.card_1.title') }}</div>

            <div class="cultura-desc">
              {{ $t('lang.professional.section_3.card_1.description') }}
            </div>
          </div>
        </div>

        <div class="mision">
          <div class="grid-title">{{ $t('lang.professional.section_3.card_2.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.professional.section_3.card_2.description') }}
            <img src="@/assets/images/svg/carrera.svg" alt="BOLSA DE EMPLEO" />
          </div>
        </div>

        <div class="vision">
          <div class="grid-title">{{ $t('lang.professional.section_3.card_3.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.professional.section_3.card_3.description') }}
            <img src="@/assets/images/svg/empresario.svg" alt="TE ACOMPAÑAMOS" />
          </div>
        </div>

        <div class="valores">
          <div class="grid-title">{{ $t('lang.professional.section_3.card_4.title') }}</div>

          <div class="grid-desc">
            {{ $t('lang.professional.section_3.card_4.description') }}
            <img src="@/assets/images/svg/clase.svg" alt="ORIENTACIÓN" />
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md bg-default text-center" id="section-see-features">
      <div class="container width-xl">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.professional.section_4.title') }}</b>
            </h2>
            <p class="text-xl">
              {{ $t('lang.professional.section_4.description') }}
            </p>
          </div>
        </div>
        <div class="row steps justify-content-lg-center">
          <!--  NEW CODE -->
          <div class="content-steps">
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-secundary2 number-content text-center number-content-one">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">01</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-secundary2 banner-content banner-content-one arrow-right-secundary">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.professional.section_4.card_1.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.professional.section_4.card_1.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-third2 number-content text-center number-content-two">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">02</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-third2 banner-content banner-content-two arrow-right-third">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.professional.section_4.card_2.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.professional.section_4.card_2.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-principal2 number-content text-center number-content-three">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">03</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-principal2 banner-content banner-content-three arrow-right-principal">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row">
                      <div class="col-md-12 title-principal" style="margin-top: 12px">
                        {{ $t('lang.professional.section_4.card_3.title') }}
                      </div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content" style="margin-top: 15px">
                        {{ $t('lang.professional.section_4.card_3.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-fourth2 number-content text-center number-content-four">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">04</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-fourth2 banner-content banner-content-four arrow-right-fourth">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.professional.section_4.card_4.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.professional.section_4.card_4.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-fifth2 number-content text-center number-content-five">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">05</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-fifth2 banner-content banner-content-five arrow-right-fifth">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.professional.section_4.card_5.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.professional.section_4.card_5.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-sixth2 number-content text-center number-content-six">
                <span class="option-step">{{ $t('lang.professional.section_4.step') }}</span>
                <br />
                <span class="number-step">06</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-sixth2 banner-content banner-content-six arrow-right-sixth">
                <div class="row-banners change-col">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.professional.section_4.card_6.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.professional.section_4.card_6.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-pencil"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END NEW CODE -->
        </div>
      </div>
    </section>

    <!-- Form -->
    <v-app>
      <v-main>
        <section class="novi-bg novi-bg-img bg-default text-center" id="professional-form">
          <div class="page-content" style="background: #f1f1f1">
            <div class="wizard-heading">
              <div class="container">
                <div class="row row-50 justify-content-sm-center">
                  <div class="col-md-12 col-lg-12 about-us">
                    <div class="row row-50 justify-content-sm-center">
                      <div class="col-md-12 col-lg-11">
                        <h1 class="font-libre title-weight">
                          <b>{{ $t('lang.professional.section_5.title') }}</b>
                        </h1>
                      </div>
                    </div>
                    <p class="text-join">
                      {{ $t('lang.professional.section_5.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-v6-content">
              <div class="wizard-form">
                <form-professional />
              </div>
            </div>
          </div>
        </section>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import FormProfessional from '../shared/components/form/form-professional.vue';
export default Vue.extend({
  name: 'Professional',
  data: () => ({}),
  metaInfo: {
    title: 'Impulsa tu carrera | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          '🔥 Únete a connecting tech people✅te ayudaremos a encontrar el proyecto que necesitas para tu éxito profesional.'
      }
    ]
  },
  methods: {
    formClicked() {
      this.$ga.event({
        'eventCategory': 'banner-profesionales-1',
        'eventAction': '/buscando',
        'eventLabel': 'button-cuentanos'
      });
    }
  },
  components: {
    FormProfessional
  }
});
</script>

